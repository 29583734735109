
.rating_scale {
    display: block;
    text-align: center;
    font-size: 0;
    font-weight: 500;
    margin:15px 5px 

}
.rating_scale label {
    cursor: pointer;
}
.rating_scale span {
    display: inline-block;
    box-sizing: border-box;
    /* padding: 0.3em 0.5em; */
    font-size: 16px;
    color: grey;
    border-top: solid 1px grey;
    border-bottom: solid 1px grey;
    border-right: solid 1px grey;
}
.rating_scale label:first-child span {
    border-left: solid 1px grey;
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
}
.rating_scale label:last-child span {
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}
.rating_scale input[type="radio"] {
    display: none;
}
.rating_scale input[type="radio"]:checked ~ span {
    background: grey;
    color: white;
}